.scrolling-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    .card {
        display: inline-block;
        /* width: 25%; */
    }
}

@media only screen and (max-width: 767px) {
  .scrolling-wrapper {
    justify-content: flex-start;
  }
}

/* Medium and large screens */
@media only screen and (min-width: 768px) {
  .scrolling-wrapper {
    justify-content: center;
  }
}


.gradient-border-offer-card {
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-color: rgba(24, 133, 74, 0.8);
    border-color: rgba(144, 189, 255, 1);
    
}



/* Scrollbar Styles */
.scrollable-container {
    /* height: 100%; */
    /* overflow: scroll; */
    /* width: 90%; */
  }
  
  /* Track */
  .scrollable-container::-webkit-scrollbar {
    width: 0px;
    height:0;
  }
  
  /* Track */
  .scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .scrollable-container::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .scrollable-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  

  /* Scrollbar Styles */
#scrollable-container {
  /* height: 100%; */
  /* overflow: scroll; */
  /* width: 90%; */
}

/* Track */
#scrollable-container::-webkit-scrollbar {
  width: 0px;
  height:0;
}

/* Track */
#scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
#scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dailogclass{
  max-width: 1000px !important;
}
